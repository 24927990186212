import styled from "styled-components";
import { SvgIconProps } from "../types";


const AccordionContainer = styled.div`
  transform: rotate(180deg);
`;

export const SvgIcon = ({ src, width, height, st={} }: SvgIconProps) => (
  <img src={`/img/svg/${src}`} alt={src} width={width} height={height} style={st}/>
);
