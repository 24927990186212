import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import { SvgIcon } from "../common/SvgIcon";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <div id="footer-class" style={{ width: '100%', maxWidth: '100%', margin: '0 auto', background: "#070c08"}}>
        <div id="sixth-inner-class" style={{ width: '100%', maxWidth: '1400px', margin: '0 auto'}}>

      <Header />
      </div></div>
      <SvgIcon src="header-img.svg" height="30px" width="100%" />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <div style={{ width: "100%" }}>
              <Route
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                component={lazy(() => import(`../pages/${routeItem.component}`))}
              />
            </div>
          );
        })}
      </Switch>
      <div id="footer-class" style={{ width: '100%', maxWidth: '100%', margin: '0 auto', background: "#070c08"}}>
        <div id="sixth-inner-class" style={{ width: '100%', maxWidth: '1400px', margin: '0 auto'}}>
          <Footer />
        </div>
      </div>
    </Suspense>
  );
};

export default Router;
